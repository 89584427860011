<template>
	<div>
		<h4 class="h4_title">账户类型</h4>
		
		<van-cell-group>
			<van-cell title="账户类型" :value="bank_type_text" />
		</van-cell-group>

		<h4 class="h4_title">账户信息</h4>

		<van-form @submit="formSubmit">

			<template v-if="type == 1">
				<van-field
					readonly
					clickable
					label="开户银行"
					:value="indexBank > -1 ? banks[indexBank].text : ''"
					placeholder="请选择"
					@click="showBank = true"
					:rules="mainlandRules.bank_id"
					required
				/>
				
				<van-popup v-model="showBank" round position="bottom">
					<van-picker
						title="开户银行"
						show-toolbar
						:columns="banks"
						:default-index="indexBank"
						@cancel="showBank = false"
						@confirm="onConfirmBank"
					/>
				</van-popup>

				<van-field 
					v-if="indexBank > -1 && mainland.bank_id == 0"
					v-model="mainland.bank_name" 
					label="其他银行" 
					placeholder="请输入"
					:rules="mainlandRules.bank_name"
					required
					/>

				<van-field 
					v-model="mainland.bank_branch" 
					label="所属支行" 
					placeholder="请以「XX省XX市XX支行」格式填写"
					:rules="mainlandRules.bank_branch"
					required
					/>
				
				<van-field 
					v-model="mainland.bank_number" 
					label="账户号码" 
					placeholder="请输入"
					:rules="mainlandRules.bank_number"
					required
					/>
			</template>


			<template v-if="type == 2">
				<van-field 
					v-model="hk.country" 
					label="国家" 
					placeholder="请输入"
					:rules="hkRules.country"
					required
				/>
				
				<van-field 
					v-model="hk.bank" 
					label="银行" 
					placeholder="请输入"
					:rules="hkRules.bank"
					required
				/>

				<van-field 
					v-model="hk.bank_address" 
					label="银行地址" 
					placeholder="请输入"
					:rules="hkRules.bank_address"
					required
				/>
				<van-field 
					v-model="hk.account_name" 
					label="账户名" 
					placeholder="请输入"
					:rules="hkRules.account_name"
					required
				/>
				<van-field 
					v-model="hk.account_no" 
					label="账户号码" 
					placeholder="请输入"
					:rules="hkRules.account_no"
					required
				/>
				<van-field
					v-model="hk.account_address" 
					label="账户地址" 
					placeholder="请输入"
					:rules="hkRules.account_address"
					required
				/>

				<van-field
					v-model="hk.swift_code"
					label="SWIFT CODE"
					placeholder="请输入"
					:rules="hkRules.swift_code"
					required
				/>
			</template>
			

			<template v-if="type == 3">
				<van-field
					readonly
					clickable
					label="主网类型"
					:value="indexType > -1 ? types[indexType].text : ''"
					placeholder="请选择主网类型"
					@click="showType = true"
					:rules="usdtRules.type"
					required
				/>
				<van-popup v-model="showType" round position="bottom">
					<van-picker
						title="主网类型"
						show-toolbar
						:columns="types"
						:default-index="indexType"
						@cancel="showType = false"
						@confirm="onConfirmType"
					/>
				</van-popup>
				
				<van-field 
					v-model="usdt.address" 
					label="地址" 
					placeholder="请输入"
					required
					:rules="usdtRules.address"
					/>
			</template>

			<div class="common_block">
				<van-button type="warning" block native-type="submit">确定</van-button>
			</div>
		</van-form>
	</div>
</template>

<script>
	export default {
		name: 'withdraw_bank',
		data() {
			return {
				mainland: {
					name: "",
					bank_name: "",
					bank_branch: "",
					bank_number: "",
				},
				mainlandRules: {
					bank_id: [
						{
							required: true,
							message: '请选择开户银行',
							trigger: 'onBlur'
						}
					],
					bank_branch: [
						{
							required: true,
							message: '请输入所属支行',
							trigger: 'onBlur'
						}
					],
					bank_name: [
						{
							required: true,
							message: '请输入所属支行',
							trigger: 'onBlur'
						}
					],
					bank_number: [
						{
							required: true,
							message: '请输入账户号码',
							trigger: 'onBlur'
						}
					],
				},

				hk: {
					name: "",
					country: "",
					bank: "",
					bank_address: "",
					account_name: "",
					account_no: "",
					account_address: "",
					swift_code: "",
				},

				hkRules: {
					country: [
						{
							required: true,
							message: '请填写国家',
							trigger: 'onBlur'
						}
					],
					bank: [
						{
							required: true,
							message: '请填写银行',
							trigger: 'onBlur'
						}
					],
					bank_address: [
						{
							required: true,
							message: '请填写银行地址',
							trigger: 'onBlur'
						}
					],
					account_name: [
						{
							required: true,
							message: '请填写账户名',
							trigger: 'onBlur'
						}
					],
					account_no: [
						{
							required: true,
							message: '请填写账户号码',
							trigger: 'onBlur'
						}
					],
					account_address: [
						{
							required: true,
							message: '请填写账户地址',
							trigger: 'onBlur'
						}
					],
					swift_code: [
						{
							required: true,
							message: '请填写SWIFT CODE',
							trigger: 'onBlur'
						}
					],
				},

				usdt: {
					address: "",
					type: "1",
					type_text: ""
				},

				usdtRules: {
					type: [
						{
							required: true,
							message: '请选择主网类型',
							trigger: 'onBlur'
						}
					],
					address: [
						{
							required: true,
							message: '请填写地址',
							trigger: 'onBlur'
						}
					]
				},


				bank_type_text: "",
				type: "0",

				showBank: false,
				indexBank: -1,
				banks: [],

				token: "",

				indexType: -1,
				showType: false,
				// types: [{type: 1, text: 'ERC20'}, {type: 2, text: 'OMNI'}, {type: 3, text: 'TRC20'}],
				types: [{type: 1, text: 'ERC20'}, {type: 3, text: 'TRC20'}],
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token

				this.type = window.sessionStorage.getItem('bank_type')

				switch(this.type) {
					case '1':
						this.bank_type_text = '国内银行账户'

						break

					case '2':
						this.bank_type_text = '海外银行账户'

						break

					case '3':
						this.bank_type_text = 'USDT'

						break

				}


				this.hk.name = window.sessionStorage.getItem('name')
				this.mainland.name = window.sessionStorage.getItem('name')

				this.init()
			}
		},
		methods: {
			init() {
				this.$axios({
					method: 'post',
					url: 'account_open/banks'
				}).then ((res) => {
					if (res.success) {

						this.banks = res.data

						this.banks.push({
							bank_id: 0,
							text: '其他'
						})
						
						this.processData()
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			},
			processData() {

				var bank_info_mainland = window.sessionStorage.getItem('bank_info_mainland')
				var bank_info_hk = window.sessionStorage.getItem('bank_info_hk')
				var bank_info_usdt = window.sessionStorage.getItem('bank_info_usdt')

				if (bank_info_mainland || bank_info_hk || bank_info_usdt) {

					if (bank_info_mainland) {
						bank_info_mainland = JSON.parse(bank_info_mainland)

						this.mainland = bank_info_mainland

						for (var i in this.banks) {
							if (this.banks[i]['bank_id'] == bank_info_mainland.bank_id) {
								this.indexBank = i
							}
						}

						if (this.indexBank == -1) {
							this.indexBank = 0
						}
					}

					if (bank_info_hk) {
						this.hk = JSON.parse(bank_info_hk)
					}

					if (bank_info_usdt) {
						bank_info_usdt = JSON.parse(bank_info_usdt)

						this.usdt = bank_info_usdt

						this.indexType = bank_info_usdt.type - 1
					}
				} else {
					this.$axios({
						method: 'post',
						url: 'cash_withdraw/index', 
						data: {
							token: this.token
						}   
					}).then ((res) => {
						if (res.success) {
							this.hk.name = res.data.name
							this.mainland.name = res.data.name

							switch (res.data.bank_type) {
								case 1:
									this.mainland.bank_name = res.data.bank_info_origin.bank_name
									this.mainland.bank_branch = res.data.bank_info_origin.bank_branch
									this.mainland.bank_number = res.data.bank_info_origin.bank_number

									if (res.data.bank_info_origin.bank_id == 0) {
										this.indexBank = 0
									} else {
										for (var i in this.banks) {
											if (this.banks[i]['bank_id'] == res.data.bank_info_origin.bank_id) {
												this.indexBank = i
											}
										}
									}

									break

								case 2:
									this.hk.country = res.data.bank_info_origin.country
									this.hk.bank = res.data.bank_info_origin.bank
									this.hk.bank_address = res.data.bank_info_origin.bank_address
									this.hk.account_name = res.data.bank_info_origin.account_name
									this.hk.account_no = res.data.bank_info_origin.account_no
									this.hk.account_address = res.data.bank_info_origin.account_address
									this.hk.swift_code = res.data.bank_info_origin.swift_code

									break

								case 3:

									this.usdt.type_text = res.data.bank_info_origin.type_text
									this.usdt.address = res.data.bank_info_origin.address

									this.indexType = this.usdt.type

									break
							}

						} else {
							this.$dialog.alert({
								message: res.error_text
							})
						}
					})
				}
			},
			formSubmit() {
				var bank_info = {}

				window.sessionStorage.removeItem('bank_info_mainland')
				window.sessionStorage.removeItem('bank_info_hk')
				window.sessionStorage.removeItem('bank_info_usdt')

				switch (this.type) {
					case '1':

						bank_info = {
							'姓名'	: this.mainland.name,
							'银行'  : this.mainland.bank_name,
							'支行'  : this.mainland.bank_branch,
							'卡号'  : this.mainland.bank_number
						}

						window.sessionStorage.setItem('bank_info_mainland', JSON.stringify(this.mainland))

						break

					case '2':

						bank_info = {
							'姓名'			: this.hk.name,
							'国家'			: this.hk.country,
							'银行'			: this.hk.bank,
							'银行地址'		: this.hk.bank_address,
							'账户名'		: this.hk.account_name,
							'账户号码'  	: this.hk.account_no,
							'账户地址'  	: this.hk.account_address,
							'SWIFT CODE'	: this.hk.swift_code
						}

						window.sessionStorage.setItem('bank_info_hk', JSON.stringify(this.hk))

						break

					case '3':

						bank_info = {
							'主网类型'		: this.usdt.type_text,
							'地址'			: this.usdt.address
						}

						window.sessionStorage.setItem('bank_info_usdt', JSON.stringify(this.usdt))

						break
				}

				window.sessionStorage.setItem('bank_info', JSON.stringify(bank_info))

				this.$router.replace('/withdraw/index?token=' + this.token)

			},
			onConfirmBank(value, index) {
				this.indexBank = index

				this.mainland.bank_id = this.banks[index].bank_id
				
				if (value.bank_id != 0) {
					this.mainland.bank_name = value.text
				} else {
					this.mainland.bank_name = ''
				}
				

				this.showBank = false
			},
			onConfirmType(value, index) {
				this.indexType = index

				this.usdt.type = value.type
				this.usdt.type_text = value.text

				this.showType = false
			},
		}
	}
</script>
